<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 线路订单
 * @Date: 2021-01-04 16:03:58
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-06 19:45:42
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/order/line.vue
-->
<template>
  <div class="user_content">
    <div v-if="!list.length">
      <van-empty
        image="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/23/yuelvhui680UxFT6uf1608649312.png"
        description="暂无数据"
      />
    </div>
    <div v-else class="orderlist">
      <ul>
        <!-- <li>
            <div class="sptitle">
                <div>陕西西安-兵马俑-华清池-华山-...</div>
                <div>进行中</div>
            </div>
            <div class="sp">订单号：160035508473909</div>
            <div class="sp sp_name">
                <span>提交时间：2019-12-07 01:10:39</span><span>支付金额：49.6</span>
            </div>
            <div class="sp_foot">
                <span>付款</span>
            </div>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Empty, Toast } from 'vant'

Vue.use(Empty)
Vue.use(Toast)

export default {
  data() {
    return {
      nolist: false,
      type: 0,
      page: 1,
      pageSize: 10,
      list: [],
      tablist: [
        { name: '全部' },
        { name: '待支付' },
        { name: '进行中' },
        { name: '已完成' },
        { name: '已取消' }
      ]
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      // const prom = {
      //   type: this.type,
      //   page: this.page,
      //   pageSize: this.pageSize
      // }
      //    liveOrderList(prom).then(res => {
      //        if(Number(res.code) === 200) {
      //             this.list = res.list
      //        }
      //    })
    },
    tabkey() {
      this.getinfo()
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .orderlist {
    & ul li {
      background: #FFFFFF;
      margin: 13px;
      padding: 10px;
      border-radius: 4px;

      .sptitle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div:nth-child(1) {
          color: #333333;
          font-size: 15px;
          font-weight: 500;
        }

        & > div:nth-child(2) {
          color: #666666;
          font-size: 10px;
        }

        .cop {
          border: 1px solid #000;
          margin-left: 15px;
          padding: 3px 7px;
          border-radius: 20px;
        }
      }

      .sp_name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        & > span:nth-child(1) {
          font-size: 13px;
        }

        & > span:nth-child(2) {
          color: #333333;
          font-size: 12px;
        }
      }

      .sp {
        margin-top: 10px;
        font-size: 13px;
        color: #999999;
        text-align: left;
      }

      .sp_foot {
        border-top: 1px solid #F2F2F2;
        margin-top: 13px;
        padding-top: 13px;
        display: flex;
        justify-content: flex-end;

        & > span {
          padding: 3px 8px;
          border: 1px solid #E43F44;
          color: #E43F44;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>
